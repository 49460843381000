import React from 'react';
import SvgButton from './../svg/Button';
import { HashLink as Link } from 'react-router-hash-link';

const Button = (props) => {
      // Check if the title is "Bloody Hell II" to decide which element to render
      const isBloodyHellII = props.title === 'Bloody Hell II';
    return(
        <span>
        <SvgButton className="project__button" disabled={isBloodyHellII}>
        </SvgButton>
        {isBloodyHellII ? (
            <a 
            href="https://store.steampowered.com/app/3010150/BLOODY_HELL_2/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="project__button-text"
            style={{ cursor: 'pointer', pointerEvents: 'auto' }}  // Enables link behavior and correct cursor
            >
                View teaser
            </a>
        ) : (
            <Link 
                to={props.buttonLink} 
                className="project__button-text"
            >
                Read more!
            </Link>
        )}
    </span>
    )
};

export default Button;
